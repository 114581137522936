import { gql } from '@apollo/client'

const GET_FLIGHT = gql`
  query GetFlight($id: ID!) {
    flight(id: $id) {
      id
      createdAt
      updatedAt
      billingAnalysisEndingDate
      carrier
      conjunctionTicketIndicator
      destinationAirportCityCode
      fareBasisTicketDesignator
      departureTime
      originAirportCityCode
      passengerSpecificData
      paymentTransactionIdentifier
      reservationBookingDesignator
      sourceOfApprovalCode
      stopoverCode
      ticketDocumentNumber
      transactionId
    }
  }
`

export default GET_FLIGHT
